import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAcademicYear } from "src/app/shared/models/academic-year.model";
import { Cacheable } from 'ts-cacheable';
import { PopupHttpErrors } from "../decorators/http-error/popup-http-errors.decorator";
import { AcademicYearRepository } from "../repositories/academic-year.repository";

@Injectable({
    providedIn: 'root'
})
export class AcademicYearService {

    constructor(private academicYearRepo: AcademicYearRepository) { }

    @Cacheable()
    @PopupHttpErrors()
    public getAcademicYears(): Observable<Array<IAcademicYear>> {
        return this.academicYearRepo.getAcademicYears();
    }

    @Cacheable()
    @PopupHttpErrors()
    public getAllAcademicYears(): Observable<Array<IAcademicYear>> {
        return this.academicYearRepo.getAllAcademicYears();
    }

    @Cacheable()
    @PopupHttpErrors()
    public getActiveAcademicYears(applicationType?: string): Observable<Array<IAcademicYear>> {
        return this.academicYearRepo.getActiveAcademicYears(applicationType);
    }

    public getCurrentAcademicYear(applicationType: string): Observable<IAcademicYear> {   
        return this.academicYearRepo.getCurrentAcademicYear(applicationType);
    }

    public getNextAcademicYear(applicationType: string): Observable<IAcademicYear> {
        return this.academicYearRepo.getNextAcademicYear(applicationType);
    }

    @Cacheable()
    @PopupHttpErrors()
    public getAcademicYear(applicationType: string, acyrCode: string): Observable<IAcademicYear> {
        return this.academicYearRepo.getAcademicYear(applicationType, acyrCode);
    }
}