<ng-container *ngIf="academicYears?.length">
  <div class="container">
    <label *ngIf="label" [for]="identifier">{{ label }}</label>
    <p *ngIf="hasError" class="error-message"><app-lu-icon icon="alert-circle"></app-lu-icon> <ng-content></ng-content></p>
    <select name="academic-year-selector" [(ngModel)]="value" id="identifier" [compareWith]="equals" [disabled]="disabled">
      <option [ngValue]="null">{{ placeholder }}</option>
      <option *ngFor="let year of academicYears" [ngValue]="year">{{ year.acyrCode | academicYearCode }}</option>
    </select>
  </div>
</ng-container>
