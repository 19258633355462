import { Observable } from 'rxjs';
import { CVABase } from './cva-base';

/**
 * Provides the common parts used by all 'selector' controls.
 *
 * @export
 * @abstract
 * @class CVASelectorBase
 * @extends {CVABase<T>}
 * @template T
 */
export abstract class CVASelectorBase<T> extends CVABase<T> {
  /**
   * The text to be used to label the select control.
   * This should be implemented by subclasses, though it can be optional.
   *
   * @abstract
   * @type {string}
   */
  public abstract label?: string;

  /**
   * The text to be used as the null option of the select control.
   * This should be implemented by subclasses, though it can be optional.
   *
   * @abstract
   * @type {string}
   */
  public abstract placeholder?: string;

  /**
   * The list of options.
   * This should be updated from the server as appropriate.
   *
   * @type {Observable<Array<T>>}
   * @memberof CVASelectorBase
   */
  public options$: Observable<Array<T>>;

  /**
   * Compares two objects and returns if they are equivalent.
   * 
   * @param a 
   * @param b 
   */
  public abstract equals(a: T, b: T): boolean
}
