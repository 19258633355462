import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ERole } from 'src/app/core/enums/roles.enum';
import { RoleService } from 'src/app/core/services/roles.service';
import { CVASelectorBase } from 'src/app/shared/classes/cva-selector-base';
import { IOption } from 'src/app/shared/models/option.model';

@Component({
  selector: 'app-application-type-selector',
  templateUrl: './application-type-selector.component.html',
  styleUrls: ['./application-type-selector.component.scss','../../../../styles/shared/form-control.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ApplicationTypeSelectorComponent, multi: true }
  ]
})
export class ApplicationTypeSelectorComponent extends CVASelectorBase<string> implements OnInit {

  @Input()
  public label = 'Application Type';

  @Input()
  public placeholder = 'Select';

  public optionList: Array<IOption> = [];

  constructor(private roleService: RoleService) {
    super();
  }

  public ngOnInit() : void {
    this.setOptionList(); 
  }

  /**
   * Considers `a` and `b` equal either if they're both falsey or they both have the same id
   * @param {typeSelection} a
   * @param {typeSelection} b
   * @returns {boolean}
   */
  public equals(a: string, b: string): boolean {
    return a == b;
  }

  private setOptionList() {
    if(this.roleService.hasRole(ERole.CURRENT_RS)) {
      this.optionList = [
        {displayText: 'Community Group Leader', value: 'CGL'},
        {displayText: 'Resident Shepherd', value: 'RS'}
      ]
    }else if(this.roleService.hasRole(ERole.FUTURE_RS)) {
      this.optionList = [
        {displayText: 'Community Group Leader', value: 'CGL'}
      ]
    }else if(this.roleService.hasAnyRole([ERole.SHEPHERDS_ADMIN, ERole.STAFF_RSCGL, ERole.STAFF_AS, ERole.STAFF_CS])) {
      this.optionList = [
        {displayText: 'Athletic Shepherd', value: 'AS'}, 
        {displayText: 'Community Group Leader', value: 'CGL'},
        {displayText: 'Commuter Shepherd', value: 'CS'},
        {displayText: 'Resident Shepherd', value: 'RS'}
      ]
    }
  }

}
