import { ControlValueAccessor } from '@angular/forms';
import { createUniqueIdentifier } from 'src/app/core/functions/create-unique-identifier.function';

/**
 * Provides the common parts of the implementation of ControlValueAccessor.
 *
 * @export
 * @abstract
 * @class CVABase
 * @implements {ControlValueAccessor}
 * @template T
 */
export abstract class CVABase<T> implements ControlValueAccessor {
  protected onChange = new Array<(value: T) => void>();
  protected onTouched = new Array<(value: T) => void>();
  protected _value: T;

  public disabled: boolean;

  public identifier: string = createUniqueIdentifier();

  public registerOnChange(fn: (value: T) => void): void {
    this.onChange.push(fn);
  }

  public registerOnTouched(fn: (value: T) => void): void {
    this.onTouched.push(fn);
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public get value(): T {
    return this._value;
  }

  public set value(value: T) {
    if (typeof value == 'string') {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._value = this.sanitize(value) as any;
    } else {
      this._value = value;
    }
    this.onChange.forEach(f => f(value));
  }

  /**
   * Pushes a new value into the control.
   * 
   * @param value 
   */
  public writeValue(value: T): void {
    this._value = value;
  }

  /**
   * Sanitizes a given string by removing angle brackets.
   *
   * @param text the text to sanitize
   */
  public sanitize(text: string): string {
    return text.replace(/[<>]/g, '');
  }
}
