import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAcademicYear } from "src/app/shared/models/academic-year.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class AcademicYearRepository {
  
    private readonly academicYearUrl: string = `${environment.apiUrl}/academic-years`;
  
    constructor(private http: HttpClient) { }

    public getAcademicYears(): Observable<Array<IAcademicYear>> {
        return this.http.get<Array<IAcademicYear>>(this.academicYearUrl);
    }

    public getAllAcademicYears(): Observable<Array<IAcademicYear>> {
        return this.http.get<Array<IAcademicYear>>(`${this.academicYearUrl}/all`)
    }

    public getActiveAcademicYears(applicationType?: string): Observable<Array<IAcademicYear>> {
        
        if(applicationType) {
            return this.http.get<Array<IAcademicYear>>(this.academicYearUrl, {params: {'active': true, 'applicationType': applicationType}});
        }
        
        return this.http.get<Array<IAcademicYear>>(this.academicYearUrl, {params: {'active': true}});
    }

    public getCurrentAcademicYear(applicationType: string): Observable<IAcademicYear> { 
        return this.http.get<IAcademicYear>(`${this.academicYearUrl}/active`, {params: {'future': false, 'applicationType': applicationType}});
    }

    public getNextAcademicYear(applicationType: string): Observable<IAcademicYear> {   
        return this.http.get<IAcademicYear>(`${this.academicYearUrl}/active`, {params: {'future': true, 'applicationType': applicationType}});
    }

    public getAcademicYear(applicationType: string, acyrCode: string): Observable<IAcademicYear> {
        return this.http.get<IAcademicYear>(`${this.academicYearUrl}/${applicationType}/${acyrCode}`);
    }

  }