import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'academicYearCode'
})
export class AcademicYearCodePipe implements PipeTransform {

    public transform(yearCode: string) {
        if (!yearCode) {
            return '';
        }
        const startYear = yearCode.substring(2, 4);
        const endYear = (+yearCode + 1).toString().substring(2, 4);
        
        return `Fall ${startYear} - Spring ${endYear}`;
    }

}
