import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportRepository } from '../repositories/report.repository';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private reportRepo: ReportRepository) { }

    public postGenerateReport(applicationType: string, acyrCode: string) : Observable<HttpResponse<Blob>> {
        return this.reportRepo.postGenerateReport(applicationType, acyrCode);
    }
  
}