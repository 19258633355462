import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { IAnswer } from "src/app/shared/models/answer.model";
import { IApplicationSearchResult } from "src/app/shared/models/application-search-result.model";
import { IApplication, IApplicationSubmission } from "src/app/shared/models/application.model";
import { IQuestionAndAnswer } from "src/app/shared/models/question.model";
import { IRecommendation } from "src/app/shared/models/recommendation.model";
import { PopupHttpErrors } from "../decorators/http-error/popup-http-errors.decorator";
import { ApplicationRepository } from "../repositories/application.repository";

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    constructor(private applicationRepository: ApplicationRepository) { }

    @PopupHttpErrors()
    public getApplication(applicationId: number) {
        return this.applicationRepository.getApplication(applicationId);
    }

    public canApplicationRollover():Observable<boolean>{
        return this.applicationRepository.canApplicationRollover();
    }

    @PopupHttpErrors()
    public getApplications(textCriteria: string, applicationType: string, gpa: number, acyrCode: string, status: string, points: number, gender: string, rating: number, followup: string, page: number): Observable<Array<IApplicationSearchResult>> {
        return this.applicationRepository.getApplications(textCriteria, applicationType, gpa, acyrCode, status, points, gender, rating, followup, page);
    }

    @PopupHttpErrors()
    public getAssignedApplications(): Observable<Array<IApplication>> {
        return this.applicationRepository.getAssignedApplications();
    }

    @PopupHttpErrors()
    public searchForHirableCGLApplications(searchInput: string, page: number): Observable<Array<IApplication>> {
        return this.applicationRepository.searchForHirableCGLApplications(searchInput, page);
    }

    @PopupHttpErrors()
    public createApplication(application: IApplicationSubmission): Observable<IApplication> {
        return this.applicationRepository.createApplication(application);
    }

    @PopupHttpErrors()
    public getMyPendingApplications(): Observable<Array<IApplication>> {
        return this.applicationRepository.getMyPendingApplications();
    }

    @PopupHttpErrors()
    public updateRecommendation(applicationId: number, recommendationResponse: IRecommendation): Observable<IApplication> {
        return this.applicationRepository.updateRecommendation(applicationId, recommendationResponse);
    }

    @PopupHttpErrors()
    public withdrawApplication(applicationId:number): Observable<IApplication> {
        return this.applicationRepository.withdrawApplication(applicationId);
    }

    @PopupHttpErrors()
    public getQuestionsAndAnswers(applicationId: number, questionnaireTypeId:number): Observable<Array<IQuestionAndAnswer>> {
        if(questionnaireTypeId) {
            return this.applicationRepository.getQuestionsAndAnswers(applicationId, questionnaireTypeId);
        }

        return of([]);
    }

    @PopupHttpErrors()
    public getInterviewAnswers(applicationId: number, questionnaireTypeId:number): Observable<Array<IAnswer>> {
        return this.applicationRepository.getInterviewAnswers(applicationId,questionnaireTypeId);
    }
    
    public approveApplication(applicationId: number, hallAssignmentId: number | undefined): Observable<IApplication>{
        return this.applicationRepository.approveApplication(applicationId, hallAssignmentId);
    }

    @PopupHttpErrors()
    public approveApplicationAsCGL(applicationId: number, hallAssignmentId: number | undefined): Observable<IApplication>{
        return this.applicationRepository.approveApplicationAsCGL(applicationId, hallAssignmentId);
    }

    @PopupHttpErrors()
    public denyApplication(applicationId: number, denialReason: string): Observable<IApplication>{
        return this.applicationRepository.denyApplication(applicationId, denialReason);
    }

    @PopupHttpErrors()
    public rolloverApplication(applicationId: number): Observable<IApplication> {
        return this.applicationRepository.rolloverApplication(applicationId);
    }

    @PopupHttpErrors()
    public updateInterviewQuestionnaire(applicationId: number, answers:Array<IAnswer>):Observable<null> {
        return this.applicationRepository.updateInterviewQuestionnaire(applicationId,answers)
    } 

    @PopupHttpErrors()
    public updateInterviewNotes(applicationId: number, notes:string):Observable<null> {
        return this.applicationRepository.updateInterviewNotes(applicationId,notes);
    }

    @PopupHttpErrors()
    public updateInterviewFollowup(applicationId: number, followUp:boolean):Observable<null> {
        return this.applicationRepository.updateInterviewFollowup(applicationId, followUp);
    }

    @PopupHttpErrors()
    public requestInterview(applicationId:number): Observable<IApplication> {
        return this.applicationRepository.requestInterview(applicationId);
    }
    
    @PopupHttpErrors()
    public completeInterview(applicationId: number, questionnaireTypeId: number): Observable<IApplication> {
        return this.applicationRepository.completeInterview(applicationId, questionnaireTypeId);
    }

    @PopupHttpErrors()
    public revertStatus(applicationId: number): Observable<IApplication> {
        return this.applicationRepository.revertStatus(applicationId);
    }

}