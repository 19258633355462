import { Component, Injector } from "@angular/core";
import { SlModalComponent, SlModalService } from "@sl/sl-angular-library";
import { IAcademicYear } from "../../models/academic-year.model";
import { ReportService } from "src/app/core/services/report.service";
import { HttpErrorResponse } from "@angular/common/http";
import { FormControl, FormGroup } from "@ngneat/reactive-forms";
import { Validators } from "@angular/forms";

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent {

  public errorModal: SlModalComponent;

  protected reportForm = new FormGroup({
    applicationType: new FormControl<string>(undefined, Validators.required),
    academicYear: new FormControl<IAcademicYear>(undefined, Validators.required),
  });

  constructor(
    private reportService: ReportService,
    private slModalService: SlModalService,
    private _injector: Injector
  ) {
  }

  public parent: SlModalComponent = this._injector.get<SlModalComponent>(SlModalComponent);

  public openErrorModal(errorMessage: string): void {
    this.errorModal = this.slModalService.createModal({
      title: "Request Failed",
      message: errorMessage,
      class: 'error-modal',
    });
  }

  protected closeReportModal(): void {
    this.reportForm.reset();
    this.parent.remove();
  }

  protected generateReport(): void {
    this.reportForm.markAllAsDirty();
    this.reportForm.markAllAsTouched();
    
    if (this.reportForm.valid) {
      const applicationType = this.reportForm.controls.applicationType.value;
      const acyrCode = this.reportForm.controls.academicYear.value.acyrCode;
      const requestObservable = this.reportService.postGenerateReport(applicationType, acyrCode);

      this.closeReportModal();

      requestObservable.subscribe({
        next: (response) => {
          // Prompt the user to input the filename
          let fileName = window.prompt('Enter the filename for the csv report:', `report_${applicationType}_${acyrCode}_${new Date().toISOString().split('T')[0]}.csv`);
          
          if (!fileName) {
            fileName = `report_${applicationType}_${acyrCode}_${new Date().toISOString().split('T')[0]}.csv`; // Default if the user cancels or enters no name
          }
          
          // Ensure the filename has the .csv extension
          if (!fileName.endsWith('.csv')) {
            fileName += '.csv';
          }

          // Create a URL for the Blob response and trigger the download
          const url = window.URL.createObjectURL(response.body as Blob); 
          const a = Object.assign(document.createElement('a'), {href: url, download: fileName}); 
          document.body.appendChild(a); 
          a.click(); 
          a.remove(); 
          window.URL.revokeObjectURL(url); 
        }, 
        error: (response: HttpErrorResponse) => {
          const reader = new FileReader();
          reader.onload = () => {
            const errorMessage = JSON.parse(reader.result as string).error || 'An unknown error occurred';
            this.openErrorModal(errorMessage);
          };
          reader.readAsText(response.error); // any data returned from the service call is considered a Blob, so convert error Blob to text
        }
      });
    }
  }
  
}