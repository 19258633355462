import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReportCriteria } from "src/app/shared/models/report-criteria.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ReportRepository {

    private readonly repoUrl: string = `${environment.apiUrl}/report`;

    constructor(private http: HttpClient) { }

    public postGenerateReport(applicationType: string, acyrCode: string): Observable<HttpResponse<Blob>> {
        const reportCriteria = {
            applicationType: applicationType || '',
            acyrCode: acyrCode || ''
        } as ReportCriteria
        return this.http.post<Blob>(`${this.repoUrl}/generate`, reportCriteria, {
            responseType: 'blob' as 'json',
            observe: 'response'
        });
    }

}