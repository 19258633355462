<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

    <!-- render only the intended icon -->
    <use attr.xlink:href="#{{icon}}"></use>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="edit">
        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="gear">
      <circle cx="12" cy="12" r="3"/>
      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="home">
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="search">
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="back-arrow">
      <line x1="19" y1="12" x2="5" y2="12"></line>
      <polyline points="12 19 5 12 12 5"></polyline>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="check-complete">
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="monogram">
      <path fill="" d="M22.3751839,8.14337541 L16.4418949,8.14337541 L16.2284221,9.10496759 C16.586303,9.13639217 17.3774082,9.2620905 17.8169111,9.79630837 C18.1057273,10.1419788 18.1936278,10.5944927 18.0868914,11.1412804 L17.2078856,15.2453307 C16.9944128,16.1817832 16.7746614,16.6782916 16.3979446,17.0805262 C16.0588995,17.4324816 15.5691677,17.627314 15.0166498,17.627314 C13.8488278,17.627314 13.1770163,17.0365318 13.1205087,15.9618112 C13.1079515,15.6852749 13.1393446,15.3521743 13.2146879,14.9750793 L14.6838833,8.14337541 L8.56851451,8.14337541 L8.35504167,9.10496759 C8.7129226,9.13639217 9.49147058,9.25580558 9.93097347,9.78373854 C10.213511,10.123124 10.3139688,10.5567832 10.2260683,11.0847162 L10.0439885,11.9897441 L5.60500929,11.9897441 L7.53882202,2.89547038 L1.48623933,2.89547038 L1.26648788,3.86963239 L1.58669713,3.90105698 C2.02620003,3.97647597 2.61011101,4.17130837 2.93659887,4.65524692 C3.16890755,5.00091731 3.23169367,5.43457653 3.11867864,5.94993966 L1.17230869,15.1447721 L11.6261989,15.1447721 L11.6073631,15.3144648 C11.5508556,15.6915598 11.5948058,16.2634871 11.5948058,16.2634871 L11.4252833,16.2572022 C11.3876116,16.2572022 11.3185469,16.2509173 11.2243677,16.2509173 C10.7283573,16.2509173 9.98748099,16.4646045 9.67982896,16.873124 C9.34706249,17.3130681 9.47263474,17.922705 9.66727174,18.3877888 C10.1318891,19.4876492 11.1113527,20.2355542 12.241503,20.5498 C12.9886579,20.7572022 13.8425492,20.8954704 14.6148186,20.8954704 C16.6365319,20.8954704 18.0429411,20.4052469 19.0412406,19.3556659 C19.8323458,18.526057 20.3785851,17.3633475 20.7176302,15.7984033 L22.3751839,8.14337541 L22.3751839,8.14337541 Z"></path>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="layout">
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="3" y1="9" x2="21" y2="9"></line>
      <line x1="9" y1="21" x2="9" y2="9"></line>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="chevron-down">
      <polyline points="6 9 12 15 18 9"></polyline>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="clipboard">
      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
      <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" id="user-secret">
      <path fill="currentColor" fill-rule="evenodd" d="M14,12 C15.6568542,12 17,13.3431458 17,15 C17,16.6568542 15.6568542,18 14,18 C12.4380272,18 11.154862,16.8062827 11.0130208,15.2813646 C10.6967516,15.0897914 10.3604746,15 10,15 C9.63952544,15 9.30324835,15.0897914 8.98580392,15.2803283 C8.84513803,16.8062827 7.56197282,18 6,18 C4.34314575,18 3,16.6568542 3,15 C3,13.3431458 4.34314575,12 6,12 C7.0266795,12 7.93290327,12.5157323 8.47373587,13.3022614 C8.94761291,13.1023748 9.45762153,13 10,13 C10.5426223,13 11.0528457,13.1024668 11.5281427,13.3022399 C12.0670967,12.5157323 12.9733205,12 14,12 Z M15.578,6.99959686 L16,8.5 L18,8.5 C18.5522847,8.5 19,8.94771525 19,9.5 C19,10.0128358 18.6139598,10.4355072 18.1166211,10.4932723 L18,10.5 L2,10.5 C1.44771525,10.5 1,10.0522847 1,9.5 C1,8.98716416 1.38604019,8.56449284 1.88337887,8.50672773 L2,8.5 L4,8.5 L4.422,6.99959686 L15.578,6.99959686 Z M14.3843021,2.78536031 L14.430053,2.9225566 L15.156,5.49959686 L4.844,5.49959686 L5.56994825,2.92255228 L5.60688722,2.80889299 L5.65275745,2.6985352 C6.00094289,1.94683075 6.89257982,1.61971397 7.64428373,1.96790058 C8.4101237,2.32263353 9.19536246,2.5 10,2.5 C10.8046377,2.5 11.5898766,2.32263346 12.3557167,1.96790039 C12.4280695,1.93438693 12.5029795,1.90669603 12.5797344,1.88509096 C13.3302666,1.67383004 14.108239,2.07285306 14.3843021,2.78536031 Z"/>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" id="user-times">
      <path fill="currentColor" fill-rule="evenodd" d="M13.9948032,15.1581806 L14,15.362012 L14,16 L2,16 L2,15.3620164 C2,13.152875 3.79085856,11.362012 6,11.362012 L6.1019489,11.3633114 L6.20383157,11.3672088 C6.85003393,11.4001807 7.44875674,11.4166667 8,11.4166667 C8.55124349,11.4166667 9.14996658,11.4001807 9.79616926,11.3672088 C12.0024378,11.2546365 13.8822303,12.9519121 13.9948032,15.1581806 Z M14.5114029,6.64038077 L14.6128994,6.70970461 L14.7071068,6.79289322 L15.5,7.585 L16.2928932,6.79289322 C16.6834175,6.40236893 17.3165825,6.40236893 17.7071068,6.79289322 C18.0675907,7.15337718 18.0953203,7.72060824 17.7902954,8.11289944 L17.7071068,8.20710678 L16.915,9 L17.7071068,9.79289322 C18.0976311,10.1834175 18.0976311,10.8165825 17.7071068,11.2071068 C17.3466228,11.5675907 16.7793918,11.5953203 16.3871006,11.2902954 L16.2928932,11.2071068 L15.5,10.415 L14.7071068,11.2071068 C14.3165825,11.5976311 13.6834175,11.5976311 13.2928932,11.2071068 C12.9324093,10.8466228 12.9046797,10.2793918 13.2097046,9.88710056 L13.2928932,9.79289322 L14.085,9 L13.2928932,8.20710678 C12.9023689,7.81658249 12.9023689,7.18341751 13.2928932,6.79289322 C13.6233369,6.46244959 14.1275055,6.41161211 14.5114029,6.64038077 Z M8,4 C9.65685425,4 11,5.34314575 11,7 C11,8.65685425 9.65685425,10 8,10 C6.34314575,10 5,8.65685425 5,7 C5,5.34314575 6.34314575,4 8,4 Z"/>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="alert-circle">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#0059c0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="25 25 50 50" id="spinner">
      <circle class="loading-path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
    </symbol>
    

     <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="person">
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </symbol>

    <symbol version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 12" id="grad-cap">
      <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Admin---All-Applications---Quick-Look" transform="translate(-1453.000000, -444.000000)" fill="#804400" fill-rule="nonzero">
          <g id="modal" transform="translate(1302.000000, 344.000000)">
            <g id="DCP-Tag-Copy" transform="translate(145.000000, 94.000000)">
              <g id="Icon" transform="translate(6.000000, 5.000000)">
                <path d="M2.91666667,7.68833333 L2.91666667,10.0216667 L7,12.25 L11.0833333,10.0216667 L11.0833333,7.68833333 L7,9.91666667 L2.91666667,7.68833333 Z M7,1.75 L0.583333333,5.25 L7,8.75 L12.25,5.88583333 L12.25,9.91666667 L13.4166667,9.91666667 L13.4166667,5.25 L7,1.75 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 8" id="down-arrow">
      <title>Vector Copy 3</title>
      <g id="Student" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
        <g id="landing-page---new-student" transform="translate(-1682.000000, -27.000000)" fill-rule="nonzero" stroke="#242425" stroke-width="2">
          <g id="Group-3" transform="translate(1560.000000, 11.000000)">
            <path d="M123,17 L129,23 L135,17" id="Vector-Copy-3" transform="translate(129.000000, 20.000000) rotate(0.000000) translate(-129.000000, -20.000000) "></path>
          </g>
        </g>
      </g>
    </symbol>

    <symbol version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 8" id="up-arrow">
      <title>Vector</title>
      <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
        <g id="Admin---All-Applications---Quick-Look" transform="translate(-934.000000, -193.000000)" fill-rule="nonzero" stroke="#242425" stroke-width="2">
          <g id="Group-4-Copy" transform="translate(750.000000, 136.000000)">
            <path d="M185,58 L191,64 L197,58" id="Vector" transform="translate(191.000000, 61.000000) rotate(-180.000000) translate(-191.000000, -61.000000) "></path>
          </g>
        </g>
      </g>
    </symbol>

    <symbol version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 23 23" id="edit">
      <title>edit</title>
      <g id="Student" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="student-profile" transform="translate(-1539.000000, -279.000000)" stroke="#000000" stroke-width="2">
          <g id="edit" transform="translate(1540.000000, 280.000000)">
            <path d="M9,2.12132031 L2,2.12132031 C0.8954305,2.12132031 0,3.01675081 0,4.12132031 L0,18.1213203 C0,19.2258898 0.8954305,20.1213203 2,20.1213203 L16,20.1213203 C17.1045695,20.1213203 18,19.2258898 18,18.1213203 L18,11.1213203" id="Path"></path>
            <path d="M16.5,0.621320312 C17.3284271,-0.207106783 18.6715729,-0.207106769 19.5,0.621320344 C20.3284271,1.44974746 20.3284271,2.79289318 19.5,3.62132031 L10,13.1213203 L6,14.1213203 L7,10.1213203 L16.5,0.621320312 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="trash">
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="10" y1="11" x2="10" y2="17"></line>
        <line x1="14" y1="11" x2="14" y2="17"></line>
    </symbol>

    <symbol version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" id="add">
      <title>Group 12</title>
      <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Shepherd-Settings" transform="translate(-396.000000, -742.000000)" stroke="#000000" stroke-width="2">
          <g id="Group-2" transform="translate(304.000000, 365.000000)">
            <g id="Group-12" transform="translate(93.000000, 378.000000)">
              <line x1="7" y1="0" x2="7" y2="14" id="Path"></line>
              <line x1="0" y1="7" x2="14" y2="7" id="Path"></line>
            </g>
          </g>
        </g>
      </g>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="x-octagon">
      <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
      <line x1="15" y1="9" x2="9" y2="15"></line>
      <line x1="9" y1="9" x2="15" y2="15"></line>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="info">
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="16" x2="12" y2="12"></line>
      <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="filter">
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="x">
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="check">
      <polyline points="20 6 9 17 4 12"></polyline>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" id="repeat">
      <path d="M19,1.5 C19.5128358,1.5 19.9355072,1.88604019 19.9932723,2.38337887 L20,2.5 L20,7.5 L15,7.5 C14.4477153,7.5 14,7.05228475 14,6.5 C14,5.98716416 14.3860402,5.56449284 14.8833789,5.50672773 L15,5.5 L16.6643295,5.49923206 C15.3237307,4.53500017 13.705015,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,11.4477153 20.4477153,11 21,11 C21.5522847,11 22,11.4477153 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C14.2023105,2 16.2929197,2.71611526 18.000984,3.99973403 L18,2.5 C18,1.98716416 18.3860402,1.56449284 18.8833789,1.50672773 L19,1.5 Z"/>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" id="file">
      <path d="M14,2 C14.2210137,2 14.434484,2.07316447 14.6080295,2.20608063 L14.7071068,2.29289322 L19.7071068,7.29289322 C19.8633871,7.44917353 19.9625983,7.65185492 19.9913276,7.86855602 L20,8 L20,19 C20,20.5976809 18.75108,21.9036609 17.1762728,21.9949073 L17,22 L7,22 C5.40231912,22 4.09633912,20.75108 4.00509269,19.1762728 L4,19 L4,5 C4,3.40231912 5.24891996,2.09633912 6.82372721,2.00509269 L7,2 L14,2 Z M12.5,4 L7,4 C6.48716416,4 6.06449284,4.38604019 6.00672773,4.88337887 L6,5 L6,19 C6,19.5128358 6.38604019,19.9355072 6.88337887,19.9932723 L7,20 L17,20 C17.5128358,20 17.9355072,19.6139598 17.9932723,19.1166211 L18,19 L18,9.5 L13.5,9.5 C12.9871642,9.5 12.5644928,9.11395981 12.5067277,8.61662113 L12.5,8.5 L12.5,4 Z M14.5,4.915 L14.5,7.5 L17.085,7.5 L14.5,4.915 Z"/>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" id="upload-bucket">
      <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <path stroke-width="2" d="M20,14 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,14"/>
        <polyline stroke-width="2" points="16.5 7.5 12 3 7.5 7.5"/>
        <line x1="12" x2="12" y1="3" y2="14" stroke-width="2"/>
      </g>
    </symbol>
   
    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="user-check">
      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
      <circle cx="8.5" cy="7" r="4"></circle>
      <polyline points="17 11 19 13 23 9"></polyline>
    </symbol>
    
    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="user-minus">
      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
      <circle cx="8.5" cy="7" r="4"></circle>
      <line x1="23" y1="11" x2="17" y2="11"></line>
    </symbol>
    
    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="user-plus">
      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
      <circle cx="8.5" cy="7" r="4"></circle>
      <line x1="20" y1="8" x2="20" y2="14"></line>
      <line x1="23" y1="11" x2="17" y2="11"></line>
    </symbol>
    
    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="user-x">
      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
      <circle cx="8.5" cy="7" r="4"></circle>
      <line x1="18" y1="8" x2="23" y2="13"></line>
      <line x1="23" y1="8" x2="18" y2="13"></line>
    </symbol>
    
    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="corner-right-up">
      <polyline points="10 9 15 4 20 9"></polyline>
      <path d="M4 20h7a4 4 0 0 0 4-4V4"></path>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="refresh-cw">
      <polyline points="23 4 23 10 17 10"></polyline>
      <polyline points="1 20 1 14 7 14"></polyline>
      <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="briefcase">
      <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="users">
      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
      <circle cx="9" cy="7" r="4"></circle>
      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="log-out">
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
      <polyline points="16 17 21 12 16 7"></polyline>
      <line x1="21" y1="12" x2="9" y2="12"></line>
    </symbol>

    <symbol xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" id="download">
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline>
      <line x1="12" y1="15" x2="12" y2="3"></line>
    </symbol>
        
</svg>