<form [formGroup]="reportForm">
    <div class="report-modal">
        <p class="error-message" *ngIf="reportForm.controls.applicationType.hasErrorAndDirty('required')">
          <app-lu-icon icon="alert-circle"></app-lu-icon> Please provide an application type
        </p>
        <app-application-type-selector formControlName="applicationType"></app-application-type-selector>
        <p class="error-message" *ngIf="reportForm.controls.academicYear.hasErrorAndDirty('required')">
          <app-lu-icon icon="alert-circle"></app-lu-icon> Please provide an academic year
        </p>
        <app-academic-year-selector formControlName="academicYear" label="Academic Year" [allAcademicYears]="false"></app-academic-year-selector>
        <div class="action-buttons">
            <button class="ghost small" type="button" (click)="closeReportModal()">Cancel</button>
            <button class="small" type="submit" (click)="generateReport()">Submit</button>
        </div>
    </div>
</form>